import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../axios';

// Toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

    useScript('assets/js/core.min.js');
    useScript('assets/js/script.js');

    useStyle('assets/css/bootstrap.css');
    useStyle('assets/css/style.css');

    const history = useHistory();
    const initialFormData = Object.freeze({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const [ FormData, updateFormData ] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...FormData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        toast.info("Your data has been collected !")

        axiosInstance
            .post(`contact/create/`, {
                name: FormData.name,
                email: FormData.email,
                phone: FormData.phone,
                subject: FormData.subject,
                message: FormData.message,
            })
            .then((res) => {
                toast.success("Team has been notified !");
            })
            .catch(error => {
                console.log(error);
                toast.error("Something went wrong, try again !");
                return error;
            });

        updateFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        })
    }

    const handleToast = () => {

        toast.success("Email successfully sent !")
        toast.success("Email successfully sent !")

    }

    
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />

                <title>Contact - Galaxy Mica</title>
            </Helmet>

            <Header />

            {/* <!-- Classic Breadcrumbs--> */}
            <section className="breadcrumb-classic">
                <div className="rd-parallax">
                    <div className="rd-parallax-layer" data-speed="0.25" data-type="media" data-url="assets/images/contact_us.png"></div>
                    <div className="rd-parallax-layer section-top-200 section-md-top-150 section-lg-top-260" data-speed="0" data-type="html">
                        <div className="shell container">
                            <ul className="list-breadcrumb">
                                <li><Link to="/">home</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!--  --> */}
            <section className="section-75 section-md-100 section-lg-150">
                <div className="rd-map-wrap">
                    <div className="shell container text-sm-left">
                        <div className="row">
                            
                            <div className="col-md-6 col-xl-5 col-xxl-4">
                                <h1>Get in touch</h1>
                                <h6 className="offset-md-top-35">Let us offer you a coffee and in return you tell us your craziest ideas.</h6>
                                <ul className="list offset-top-20 list-lg-middle text-left">
                                    <br/>
                                    <span className="small text-spacing-340 text-uppercase text-regular offset-top-40 offset-md-top-60">Headquaters</span>
                                    <br/>

                                    <li>
                                        <div className="unit unit-horizontal unit-spacing-md">
                                            <div className="unit-left"><span className="icon icon-primary fa-map-marker"></span></div>
                                            <div className="unit-body">
                                                <h6><a href="https://goo.gl/maps/Enbm2VCvCUxBuWE88" target="_blank">1, Lakshmi Complex, Handloom, Near Satluj Hotel, Naroda Road, Ahmedabad, Gujarat</a></h6>
                                            </div>
                                        </div>
                                    </li>

                                    <br/>
                                    <span className="small text-spacing-340 text-uppercase text-regular offset-top-40 offset-md-top-60">For Local Enquiries</span>
                                    <br/>

                                    <li>
                                        <div className="unit unit-horizontal unit-spacing-md">
                                            <div className="unit-left"><span className="icon icon-primary fa-phone"></span></div>
                                            <div className="unit-body">
                                                <h6><a href="tel:+919426530996">+91 9426530996</a></h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="unit unit-horizontal unit-spacing-md">
                                            <div className="unit-left"><span className="icon icon-primary fa-envelope"></span></div>
                                            <div className="unit-body">
                                                <h6><a className="text-primary" href="mailto:info@galaxymica.com">info@galaxymica.com</a></h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="unit unit-horizontal unit-spacing-md">
                                            <div className="unit-left"><span className="icon icon-primary fa-envelope"></span></div>
                                            <div className="unit-body">
                                                <h6><a className="text-primary" href="mailto:infogalaxymica@gmail.com">infogalaxymica@gmail.com</a></h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                
                            </div>

                            <div className="col-md-6 offset-xl-1 col-xl-5 offset-xxl-2 col-xxl-6 position-static">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.7858477093723!2d72.64386661550009!3d23.068311520404524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e86c3cfa7f323%3A0xd15cbf559e0a2d66!2sGalaxy%20Mica%20Private%20Limited!5e0!3m2!1sen!2sin!4v1624346639946!5m2!1sen!2sin" width="130%" height="100%" style={{ border:'0' }} allowFullScreen="" loading="lazy"></iframe>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Form --> */}
            <section className="section-50 section-md-75 section-md-100 section-lg-120 section-xl-150 bg-wild-sand">
                <div className="shell container text-left">
                    <h2><span className="small">contact us</span>If you have any questions, just fill in the contact form, and we will answer you shortly.</h2>
                    <form className="rd-mailform text-left" data-form-output="form-output-global" data-form-type="contact" method="post">
                        <div className="row offset-top-40 offset-md-top-60">
                            <div className="col-xl-4 col-lg-6">
                                <div className="form-group postfix-xl-right-40">
                                    {/* <label className="form-label" htmlFor="name">Name *</label> */}
                                    <input className="form-control" id="name" name="name" type="text" data-constraints="@Required" placeholder="Name *" value={ FormData.name } onChange={ handleChange } />
                                </div>
                                <div className="form-group postfix-xl-right-40">
                                    <input className="form-control" id="email" type="email" name="email" data-constraints="@Email @Required" placeholder="Email *" value={ FormData.email } onChange={ handleChange } />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 offset-top-20 offset-md-top-0">
                                <div className="form-group postfix-xl-right-40">
                                    <input className="form-control" id="phone" type="text" name="phone" data-constraints="@Required" placeholder="Phone *" value={ FormData.phone } onChange={ handleChange } />
                                </div>
                                <div className="form-group postfix-xl-right-40">
                                    <input className="form-control" id="subject" type="text" name="subject" data-constraints="@Required" placeholder="Subject *" value={ FormData.subject } onChange={ handleChange } />
                                </div>
                            </div>
                            <div className="col-xl-4 offset-top-20 offset-lg-top-0">
                                <div className="form-group postfix-xl-right-40">
                                    <textarea className="form-control" id="message" name="message" data-constraints="@Required" placeholder="Message *" value={ FormData.message } onChange={ handleChange }></textarea>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-form btn-primary" type="submit" onClick={ handleSubmit }>Send Message</button>
                    </form>
            </div>
            </section>

            <section className="section-50 section-sm-75"></section>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            
        </>
    )
}


const useScript = url => {
    useEffect(() => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            script.defer = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
    }, [url]);
};

const useStyle = url => {
    useEffect(() => {
            const style = document.createElement('link');
			style.rel = "stylesheet";
            style.href = url;
            style.async = false;
            style.defer = true;
            document.head.appendChild(style);
            return () => {
                document.head.removeChild(style);
            };
    }, [url]);
};


export default Contact
