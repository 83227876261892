import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from '../App';
import VisualGallery from '../upper_components/visual-gallery/visualGallery';
import history from '../components/history';

function Index() {
    return (
        <>
        <Router history={history}>
            
            <Switch>
                <Route path='/visual-gallery'>
                    <VisualGallery />
                </Route>
                <Route path='/'>
                    <App />
                </Route>
            </Switch>

        </Router>
        </>
    );
}

export default Index
