import Footer from './components/footer';
import Home from './pages/home';
import Events from './pages/events';
import Product from './pages/product';
import Collection from './pages/collection';
import Search from './pages/search';
import Catalogue from './pages/catalogue';
import Contact from './pages/contact';
import Error from './pages/error';
import BottomScripts from './components/bottomScripts';
import { Router, Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import React, { Component, useEffect, useState } from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';

function App() {

	const { url } = useRouteMatch()

	// useStyle('assets/vsg/css/bootstrap.css');
    // useStyle('assets/vsg/css/style.css');

	return (

		<>
		
		<SimpleReactLightbox>

			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#000000" />
				<meta name="description" content="Web site created using create-react-app" />

				<title>Home - Galaxy Mica</title>

				{/* <link rel='stylesheet' href='assets/css/bootstrap.css' />
				<link rel='stylesheet' href='assets/css/style.css' /> */}

			</Helmet>


			{/* <!-- Page--> */}
			<div className="page text-center text-md-left">

			{/* <!-- Page Content--> */}
			<main className="page-content">
				{/* <Router> */}
					<Switch>
						<Route exact path='/' component={Home} ></Route>
						<Route exact path='/search' component={Search} ></Route>
						<Route exact path='/products' component={Product} ></Route>
						<Route path='/product/:name_slug' component={Collection} ></Route>
						{/* <Route exact path='/collection' component={Collection} ></Route> */}
						<Route path='/events' component={Events} ></Route>
						<Route path='/contact'component={Contact} ></Route>
						{/* <Route component={ Error }></Route> */}
						{/* <Route path='/visual-gallery'component={Contact} ></Route> */}
					</Switch>
				{/* </Router> */}
				
				</main>

				<Footer />

			</div>

			{/* <Router>
				<Switch>
					<Route path='/catalogue'>
						<Catalogue />
					</Route>
				</Switch>
			</Router> */}

		</SimpleReactLightbox>

		</>
			
	);
}

const useStyle = url => {
    useEffect(() => {
            const style = document.createElement('link');
			style.rel = "stylesheet";
            style.href = url;
            style.async = false;
            style.defer = true;
            document.head.appendChild(style);
            return () => {
                document.head.removeChild(style);
            };
    }, [url]);
};

export default App;
