import React, { useEffect } from 'react';
import { Link, useRouteMatch, Router } from 'react-router-dom';




const Header = () => {

    const { url } = useRouteMatch()

    // useStyle('assets/css/bootstrap.css');
    // useStyle('assets/css/style.css');

    return (

    // handleClick = () => {
    //     this.props.history.push("/collection");
    // }
        <div>

            {/* <!-- Prealoader --> */}
            {/* <!-- <div className="page-loader">
                <div>
                <div className="page-loader-body">
                    <div className="cssload-loader">
                    <div className="cssload-inner cssload-one"></div>
                    <div className="cssload-inner cssload-two"></div>
                    <div className="cssload-inner cssload-three"></div>
                    </div>
                </div>
                </div>
            </div> --> */}

            {/* <!-- Page Header--> */}
            <header className="page-head">
                <div className="rd-navbar-wrap" style={{ height: '0px !important',}}>

                    <nav className="rd-navbar" data-stick-up-clone="false" data-layout="rd-navbar-fixed" data-md-layout="rd-navbar-static" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-static" data-lg-stick-up-offset="40px">
                        <div className="rd-navbar-inner">

                            <div className="rd-navbar-panel">
                                <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                                <div className="rd-navbar-brand">
                                <Link className="brand-name" to="/">
                                    <span className="brand-logo veil">
                                        <img src="assets/images/galaxymica-R.png" alt="Galaxy Mica" title="Galaxy Mica" style={{ height: '45px', display: 'block' }} />
                                    </span>
                                        <img src="assets/images/galaxymica-R.png" alt="Galaxy Mica" title="Galaxy Mica" style={{ height: '55px' }} />
                                </Link>
                                </div>
                            </div>

                            {/* <Router> */}
                            <div className="rd-navbar-nav-wrap">
                                <ul className="rd-navbar-nav">
                                    <li className="active"></li>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to='/products'>Products</Link>
                                    </li>
                                    <li>
                                        {/* <a onClick={this.handleClick}>Collection</a> */}
                                        <a href="assets/pdf/Like-4-spread.pdf" target='_blank'>Catalogue</a>
                                    </li>
                                    <li>
                                        <a href="/visual-gallery">Visual Gallery</a>
                                    </li>
                                    <li>
                                        <Link to="/events">Events</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                            {/* </Router> */}

                        </div>
                    </nav>

                    {/* <nav style={displayNone} className="rd-navbar rd-navbar-default rd-navbar--is-clone rd-navbar-static rd-navbar--is-stuck" data-stick-up-clone="true" data-layout="rd-navbar-fixed" data-md-layout="rd-navbar-static" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-static" data-lg-stick-up-offset="40px"> */}
                    {/* <nav className="rd-navbar rd-navbar-default rd-navbar--is-clone rd-navbar-static rd-navbar--is-stuck" data-stick-up-clone="false" data-layout="rd-navbar-fixed" data-md-layout="rd-navbar-static" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-static" data-lg-stick-up-offset="40px">
                        <div className="rd-navbar-inner">

                        <div className="rd-navbar-panel">
                            <button className="rd-navbar-toggle toggle-cloned" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                            <div className="rd-navbar-brand">
                            <a className="brand-name" href="index.html">
                                <span className="brand-logo veil">
                                    <img src="images/galaxymica-R.png" alt="Galaxy Mica" title="Galaxy Mica" style={{ height: '45px', display: 'block' }} />
                                </span>
                                <img src="images/galaxymica-R.png" alt="Galaxy Mica" title="Galaxy Mica" style={{ height: '55px' }} />
                            </a>
                            </div>
                        </div>

                        <div className="rd-navbar-nav-wrap toggle-cloned-elements">
                            <ul className="rd-navbar-nav">
                            <li className="active"></li>
                            <li><a href="index.html">Home</a></li>
                            <li><a onClick={this.handleClick}>Collection</a></li>
                            <li><a href="catalogue.html">Catalogue</a></li>
                            <li><a href="#">Visual Gallery</a></li>
                            <li><a href="events.html">Events</a></li>
                            <li><a href="contact.html">Contact-Us</a></li>
                            <li className="rd-navbar--has-dropdown rd-navbar-submenu"><a href="#">Pages</a>
                                <ul className="rd-navbar-dropdown">
                                <li><a href="typography.html">Typography</a></li>
                                <li><a href="tabs.html">Tabs</a></li>
                                <li><a href="buttons.html">Buttons</a></li>
                                <li><a href="grid.html">Grid</a></li>
                                <li><a href="tables.html">Tables</a></li>
                                <li><a href="forms.html">Forms</a></li>
                                <li><a href="icons.html">Icons</a></li>
                                <li><a href="site-map.html">Site Map</a></li>
                                <li><a href="404-page.html">404 Page</a></li>
                                <li><a href="503-page.html">503 Page</a></li>
                                <li><a href="maintenance.html">Maintenance</a></li>
                                <li><a href="coming-soon.html">Coming Soon</a></li>
                                <li><a href="privacy.html">Privacy Policy</a></li>
                                </ul>
                            <span className="rd-navbar-submenu-toggle"></span></li>
                            </ul>
                        </div>

                        </div>
                    </nav> */}
            
                
                </div>
            </header>
            
        </div>
    )
}

// const useStyle = url => {
//     useEffect(() => {
//             const style = document.createElement('link');
// 			style.rel = "stylesheet";
//             style.href = url;
//             style.async = false;
//             style.defer = true;
//             document.head.appendChild(style);
//             return () => {
//                 document.head.removeChild(style);
//             };
//     }, [url]);
// };

export default Header;
// export default withRouter(Header);
