import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
// import '../assets/css/bootstrap.css';
// import '../assets/css/style.css';
import { Helmet } from 'react-helmet';
// import ScriptTag from 'react-script-tag';
// import BottomScripts from '../components/bottomScripts';
import axios from 'axios';
import axiosInstance from '../axios';
import { useHistory } from "react-router-dom";
import { SRLWrapper } from 'simple-react-lightbox';
// import '../assets/css/module.bootstrap.css';
// import '../assets/css/module.style.css';
import history from '../components/history';
import { useLocation } from 'react-router';



const Collection = () => {

    useScript('assets/js/core.min.js');
    useScript('assets/js/script.js');

    useStyle('assets/css/bootstrap.css');
    useStyle('assets/css/style.css');

    const location = useLocation();
    const product_name = location.pathname.split('/')[2];

    // Fstate : Fullsheet (fetched fullsheets will be stored here)
    const [data, setData] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(true);
    const [retrigger, setRegtrigger] = useState(0);

    // Pstate : Pagination
    const [currnetPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(28);

    const pages = [];
    for(let i=1; i<=Math.ceil(data.length/itemsPerPage); i++){
        pages.push(i);
    }

    const indexOfLastItem = currnetPage*itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    //Pevent : Pagination (page click event)
    const handlePageClick = (event) => {
        console.log(event.target.id);
        setCurrentPage(event.target.id);
        window.scrollTo({
            top: 500, 
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }

    // use-effect : Fullsheet (fetching the fullsheets)
    useEffect(() => {
        if (fetchStatus == true){

            async function fetchProducts() {
                const request = await axios.get('https://panel.galaxymica.com/api/collection/' + product_name, {
                    headers: {
                        Authorization: 'Bearer ' + '90411fb0941f2b9c5e4f3bc976b301f8c1ec31e3 token',
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                });
                setData(request.data);
                return request;
            }

            fetchProducts()
            .then(function() {
                setFetchStatus(true);
            })
            .catch(error => {
                console.log(error);
                setFetchStatus(false);
                return error;
            })

        }
    }, [fetchStatus]);


    // Set-up : search functionality
    const initialSearchFormData = Object.freeze({
        search: '',
    });

    const [ searchFormData, updateSearchFormData ] = useState(initialSearchFormData)

    const handleSearchChange = (e) => {
        updateSearchFormData({
            ...searchFormData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearchSubmit = (e) => {

        e.preventDefault();

        axiosInstance
            .get(`collection/search?q=` + searchFormData.search)
            .then((res) => {
                console.log("search successful")
                console.log(searchFormData.search)
                console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                return error;
        });

        updateSearchFormData({
            search: "",
        })
    }


    const history = useHistory();

    const demoPush = (e) => {
        e.preventDefault();
        history.push("/search?q=" + searchFormData.search);
        // history.replace({
        //     pathname: '/search',
        //     search: '?q=' + searchFormData.search,
        // })
    }

    
    return (
        
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />

                <title>Collection - Galaxy Mica</title>
            </Helmet>

            <Header />

            {/* <!-- Classic Breadcrumbs--> */}
            <section className="breadcrumb-classic">
                <div className="rd-parallax">
                    <div className="rd-parallax-layer" data-speed="0.25" data-type="media" data-url="assets/images/collection.png"></div>
                    <div className="rd-parallax-layer section-top-200 section-md-top-150 section-lg-top-260" data-speed="0" data-type="html">
                    <div className="shell container">
                        <ul className="list-breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/products">Products</Link></li>
                            <li><Link to={ "/products/" + product_name }>{ product_name }</Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </section>

            <section className="section-50 section-md-60 section-lg-70">

                {/* <!-- Title-Para --> */}
                <div className="shell container">
                    <div className="row justify-content-lg-between">
                        <div className="col-lg-8 col-xl-7 col-xxl-6">
                            <div className="inset-md-right-30 inset-lg-right-0">
                                <h1>Collection</h1>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
            fetchStatus ? 
                   
                <>          
                <section style={{ paddingBottom: '90px' }}>
                    <div className="container">
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-6 col-xl-5 col-xxl-4">
                                <form className="text-left rd-mailform-subscribe" style={{ overflow: 'hidden' }} method="get" onSubmit={ demoPush }>
                                    <div className="form-group">
                                        <input className="form-control form-control-last-child" value={ searchFormData.search } onChange={ handleSearchChange } type="text" name="search" placeholder="Product Number" required />
                                    </div>

                                    <button className="btn btn-primary" type="submit">search</button>
                                    
                                    {/* <Link to={{ pathname: '/search', search:'?q=' + searchFormData.search }} className="btn btn-primary" type="submit">search</Link> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section style={{ paddingBottom: '90px' }}>
                    <div className="container">
                        <button className="btn btn-primary" type="submit" onClick={ demoPush }>search</button>
                    </div>
                </section> */}


                <section className="section" style={{ paddingBottom: '120px'}}>
                    <div className="container">
                        <div className="row">
                        
                            {/* <!-- Filters--> */}
                            {/* <div className="col-lg-12">
                                <div className="isotope-filters isotope-filters-horizontal">
                                <ul className="list-isotop">
                                    <li><a data-isotope-filter="*" data-isotope-group="gallery" href="#">All</a></li>
                                    <li><a className="active" data-isotope-filter="Building" data-isotope-group="gallery" href="#">Building</a></li>
                                    <li><a data-isotope-filter="Consulting" data-isotope-group="gallery" href="#">Consulting</a></li>
                                    <li><a data-isotope-filter="Interior" data-isotope-group="gallery" href="#">Interior</a></li>
                                    <li><a data-isotope-filter="Office" data-isotope-group="gallery" href="#">Office</a></li>
                                </ul>
                                </div>
                            </div> */}

                            {/* <!-- Content--> */}
                            <div className="col-lg-12">
                                <SRLWrapper>
                                <div className="row">
                                    {
                                        currentItems.map((data) => {
                                            return (
                                            <div key={data.id} className="col-xl-3 col-md-4 col-xs-6 col-sm-6 col-6" style={{ paddingTop: '30px', }}>
                                                <div className="" data-wow-delay=".5s">
                                                    <img className="img-responsive" src={data.image1} alt="" />
                                                    <div className="small">
                                                        <h3>{data.product_code}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                                </SRLWrapper>
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <div className="col-lg-12 col-sm-8 mt-4" style={{ margin: 'auto' }}>

                                <div className="offset-top-60 group-xl d-flex justify-content-center" style={{ margin: 'auto' }}>
                                    {
                                        pages.map((pages) => {
                                            return(
                                                <button key={ pages } id={ pages } className={ currnetPage == pages ? "btn-small btn-primary btn-round active" : "btn-small btn-primary btn-round" } href="" onClick={ handlePageClick }>{ pages }</button>
                                            )
                                        })
                                    }
                                </div>
                            </div> 
                        </div> 

                    </div>

                </section>
                </>

            : 
                <section style={{ paddingBottom: '90px' }}>
                    <div className="container">
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-12 col-xl-12 col-xxl-12 d-flex justify-content-center" style={{ paddingBottom: '30px' }}>
                                <h6>Something went wrong while fetching the products</h6>
                            </div>
                            <div className="col-lg-12 col-xl-12 col-xxl-12 d-flex justify-content-center">
                                <button className="btn btn-primary btn-icon btn-icon-right" onClick={ () => setFetchStatus(true) }>Refresh<span className="icon fa-refresh"></span></button>
                            </div>
                        </div>
                    </div>
                </section>
            }

                 
            
            {/* <BottomScripts /> */}
            
        </>

    )
}

const useScript = url => {
    useEffect(() => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            script.defer = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
    }, [url]);
};

const useStyle = url => {
    useEffect(() => {
            const style = document.createElement('link');
			style.rel = "stylesheet";
            style.href = url;
            style.async = false;
            style.defer = true;
            document.head.appendChild(style);
            return () => {
                document.head.removeChild(style);
            };
    }, [url]);
};


export default Collection

