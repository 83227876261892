import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../components/header';
import { Helmet } from 'react-helmet';
import { SRLWrapper } from 'simple-react-lightbox';
// import('../assets/css/module.bootstrap.css');
// import('../assets/css/module.style.css');

// import  '../assets/css/bootstrap.scoped.css';
// import '../assets/css/home.scoped.css';
// import '../assets/fonts/fontawesome-webfont.woff2';



// class Home extends React.Component {
//     render() {

const Home = () => {

    useScript('assets/js/core.min.js');
    useScript('assets/js/script.js');

    useStyle('assets/css/bootstrap.css');
    useStyle('assets/css/style.css');

    // require("../assets/css/bootstrap.css");
    // require("../assets/css/style.css");

    // function removejscssfile(filename, filetype){
    //     var targetelement=(filetype=="js")? "script" : (filetype=="css")? "link" : "none" //determine element type to create nodelist from
    //     var targetattr=(filetype=="css")? "src" : (filetype=="css")? "href" : "none" //determine corresponding attribute to test for
    //     var allsuspects=document.getElementsByTagName(targetelement)
    //     for (var i=allsuspects.length; i>=0; i--){ //search backwards within nodelist for matching elements to remove
    //     if (allsuspects[i] && allsuspects[i].getAttribute(targetattr)!=null && allsuspects[i].getAttribute(targetattr).indexOf(filename)!=-1)
    //         allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
    //     }
    // }
     
    // removejscssfile("somescript.js", "js") //remove all occurences of "somescript.js" on page
    // removejscssfile("somestyle.css", "css") //remove all occurences "somestyle.css" on page

    // const link = document.createElement('link');
    // link.setAttribute('rel', 'stylesheet');
    // link.setAttribute('href', '../assets/css/bootstrap.css');

    // // Append to the `head` element
    // document.head.appendChild(link);

    // const link2 = document.createElement('link');
    // link2.setAttribute('rel', 'stylesheet');
    // link2.setAttribute('href', '../assets/css/style.css');

    // // Append to the `head` element
    // document.head.appendChild(link2);


    return (
        <>

            <Helmet>

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />

                <title>Home - Galaxy Mica</title>

                {/* <link rel='stylesheet' href='assets/css/bootstrap.css' async />
                <link rel='stylesheet' href='assets/css/style.css' async /> */}
            </Helmet>
            
            <Header />

            {/* <!-- Slider --> */}
            <div className="swiper-wrap-default">
            <div className="swiper-container swiper-slider" data-height="100vh" data-min-height="300px" data-slide-effect="fade" data-autoplay="false">
                <div className="swiper-wrapper">
                <div className="swiper-slide" data-slide-bg="assets/images/slider_1.jpg">
                    <div className="swiper-slide-caption">
                    <div className="shell container">
                        <div className="row section-100-vh align-items-sm-center">
                        <div className="col-lg-10 col-xxl-8 col-xl-11">
                            <div className="gel-block">
                            <h1 className="fadeInLeftSm animated" data-caption-animate="fadeInLeftSm" data-caption-delay="650">Inspired from nature, beautified by us.</h1>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="swiper-slide" data-slide-bg="assets/images/slider_2.jpg">
                    <div className="swiper-slide-caption">
                    <div className="shell container">
                        <div className="row section-100-vh align-items-sm-center">
                        <div className="col-lg-10 col-xxl-8 col-xl-11">
                            <div className="gel-block">
                            <h1 data-caption-animate="fadeInLeftSm" data-caption-delay="650">Homes that cast the spell on every heart.</h1>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="swiper-slide" data-slide-bg="assets/images/slider_3.jpg">
                    <div className="swiper-slide-caption">
                    <div className="shell container">
                        <div className="row section-100-vh align-items-sm-center">
                        <div className="col-lg-10 col-xxl-8 col-xl-11">
                            <div className="gel-block">
                            <h1 data-caption-animate="fadeInLeftSm" data-caption-delay="650">Let the colors inspire you and be shown up.</h1>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/* <!-- Swiper Navigation--> */}
                <div className="swiper-button-prev"><span>Prev</span></div>
                <div className="swiper-button-next"><span>Next</span></div>
            </div>
            <div className="swiper-left-sidebar"><span className="small text-uppercase text-bold text-spacing-340 text-center">GLOBALLY KNOWN LAMINATE BRAND</span>
                <div className="divider-custom veil reveal-lg-block"></div>
                <ul className="list text-center">
                <li className="wow fadeIn"><a className="ioon icon-sm icon-white fa-facebook" href="https://www.facebook.com/Galaxy-Mica-Pvt-Ltd-357623024448869/" target="_blank"></a></li>
                <li className="wow fadeIn"><a className="ioon icon-sm icon-white fa-instagram" href="https://www.instagram.com/galaxymica/" target="_blank"></a></li>
                </ul>
            </div>
            </div>

            {/* <!-- About Us --> */}
            <section className="section-75 section-md-top-70 section-lg-top-100" style={{ paddingBottom: '0px' }}>
            <div className="shell container text-sm-left">
                <div className="blog-post">
                <div className="row">

                    <div className="offset-lg-2 col-xl-9 col-lg-10 col-xxl-8">
                    <p className="blog-post-time" style={{ paddingLeft: '5px' }}>KNOW US</p>
                    <h1 style={{ marginTop: '20px' }}>Inspiration is here. We get inspired, we change your world.</h1>
                    {/* <!-- <h6>Today we commemorated the completion of steel erection at the 667 Congress Street Apartments project with a traditional topping out ceremony.</h6> --> */}
                    <p>Galaxy Mica is a celebrated brand and is measured the most important leading industrial groups pan India and spreading worldwide.  We had a vision to donate to the enlargement of laminate and building sectors.</p>
                    </div>

                    <div className="offset-lg-1 col-lg-11 col-lg-10 col-xxl-9 offset-top-0">
                    <div className="well-custom well-custom-classic">
                        <h5>“A calm, quiet and harmonious interior can be as beneficial to health as a sensible diet and regular exercise.” <br /><br /> <span style={{ float: "right" }}> - Kelly Hoppen</span></h5>
                    </div>
                    </div>

                    <div className="offset-lg-2 col-xl-9 col-lg-10 col-xxl-8 offset-top-0">
                    <p>We aim at supplying the highest standards of quality products. Since its inception, Galaxy has wide developments to become one of the most protruding local and regional brand.</p>
                    </div>

                    <div className="offset-lg-1 col-lg-10 offset-top-40 offset-md-top-50 col-xl-8 col-xxl-5"><img className="img-responsive" src="assets/images/Know-Us.jpg" alt="" width="610" height="408"/>
                    </div>

                    <div className="col-lg-10 offset-top-40 offset-md-top-50 col-xl-8 offset-lg-2 offset-xxl-0 col-xxl-4">
                    <p className="inset-xl-left-40 inset-md-left-25">It is managed by a team of experienced professionals with long years of experience in various disciplines. Backed by a collective experience of many years, Galaxy leverages its high profile aptitudes to provide a complete portfolio of innovative products.</p>
                    <p className="inset-xl-left-40 inset-md-left-25">Our products meet the highest international standards, aiding the industry in terms of conveying progressive set-up, industrial, commercial and residential improvement projects. We also beautify spaces by instilling creativity into every work that we do. Thus, we turn furniture into pure beauty and brilliant design.</p>
                    </div>

                    <div className="offset-lg-2 col-xl-9 col-lg-10 col-xxl-8 offset-top-40">
                    <p>The wood that we use is brought from the most notable wood species around the world. Each wood is a nature’s master creation itself, our innovation in it makes it even more beautiful and more impactful. We look forward to keep creating timeless home splendors.</p>
                    </div>

                </div>
                </div>
                <hr style={{ border: '1px solid #e1e1e1', marginTop: '80px', width: '80%' }} />
            </div>
            </section>

            {/* <!-- Like --> */}
            <section className="section-75" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <div className="shell container text-sm-left">
                    <div className="bg-table bg-table-well">
                    <div className="row justify-content-md-center">
                        <div className="col-xl-10">
                        <div className="row offset-top-30">
                            <div className="col-md-8">
                            <div className="inset-lg-right-30 inset-xl-right-40">
                                <h5 className="text-regular font-default text-spacing-0">About Like</h5>
                                <p>
                                    A motivating collection is always pricy to the art lovers. Be it laminates, colors or decorating 
                                    homes, the designs and colors need to be pleasing to the owner. Like by Galaxy Mica is such a 
                                    catalogue to present all the selective products that every home owner would love. The catalogue 
                                    was designed thoughtfully to attract the wide mass of clients to explore and like the laminate 
                                    collection for their homes. This catalogue works as a showstopper in a show. It leaves a 
                                    lasting impact. Every laminate mean something to someone, and through this catalogue, it 
                                    is proved for each product to get liked! This has been welcomed, loved and adored with open 
                                    hands widely in the country and out. One can get bold design statements with these laminates.
                                </p>
                            </div>
                            </div>
                            <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src="assets/images/h1.png" alt="" style={{ justifyContent: 'center' }} />
                            </div>
                            {/* <!-- <div className="col-sm-12 offset-top-40"><a className="link-custom" href="#">Read more questions</a></div> --> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            
            {/* <!-- Services --> */}
            <section className="section-75" >
            <div className="custom-wrapper">
                <div className="custom-wrapper-left">
                <div className="custom-wrapper-content">
                    <div className="divider-block">
                    <h2 className="wow animagion" data-wow-delay=".05s">Services</h2>
                    <h6 className="wow animagion" data-wow-delay=".1s">Always honest to our customers!</h6>
                    <p className="wow animagion" data-wow-delay=".15">Standing by the commitment, we reach pan India with timely delivery and that is where our services get recognized by all our clients and their contacts and further more. The products promise water resistance, microbe resistance, heat resistance and are easy to maintain, long lasting and also available in huge variety. Passionate about making our products accessible to masses, we have provided our services to the homes and we share our expertise with public through our services.</p>
                    </div>
                </div>
                </div>
                <div className="custom-wrapper-right">
                <div className="owl-carousel-custom" data-photo-swipe-gallery="gallery">
                    <div className="owl-carousel" data-items="1" data-sm-items="2" data-md-items="1" data-lg-items="2" data-dots="false" data-stage-padding="0" data-loop="true" data-nav="true" data-margin="15px" data-mouse-drag="false" data-dots-speed="800" data-drag-end-speed="800" data-nav-speed="700">
                        <a className="thumb-custom" data-photo-swipe-item="" href="assets/images/services_1.jpg" data-author="">
                            <img className="img-responsive image-first wow animagion" src="assets/images/services_1.jpg" alt="" data-wow-delay=".05s" />
                        </a>
                        <a className="thumb-custom" data-photo-swipe-item="" href="assets/images/services_2.jpg" data-author="">
                            <img className="img-responsive image-first wow animagion" src="assets/images/services_2.jpg" alt="" data-wow-delay=".1s" />
                        </a>
                        <a className="thumb-custom" data-photo-swipe-item="" href="assets/images/services_3.jpg" data-author="">
                            <img className="img-responsive image-first" src="assets/images/services_3.jpg" alt="" />
                        </a>
                        <a className="thumb-custom" data-photo-swipe-item="" href="assets/images/services_4.jpg" data-author="">
                            <img className="img-responsive image-first" src="assets/images/services_4.jpg" alt="" />
                        </a>
                    </div>
                </div>
                </div>
            </div>
            </section>

            {/* <!-- Counter--> */}
            <section className="section-75 section-md-120 section-xl-130">
            <div className="shell container">
                <div className="row text-center">
                    <div className="col-lg-3 col-md-6 offset-top-40 offset-md-top-0">
                        <div className="counter" data-from="0" data-to="3542"></div>
                        <div className="counter-cite">Happy Customer</div>
                    </div>
                    <div className="col-lg-3 col-md-6 offset-top-40 offset-md-top-0">
                        <div className="counter" data-from="100" data-to="238"></div>
                        <div className="counter-cite">partners</div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="counter" data-from="10" data-to="165"></div>
                        <div className="counter-cite">Products</div>
                    </div>
                    <div className="col-lg-3 col-md-6 offset-top-40 offset-sm-top-0">
                        <div className="counter" data-from="1" data-to="12"></div>
                        <div className="counter-cite">Finishes</div>
                    </div>
                </div>
            </div>
            </section>

            {/* <!-- Product Features--> */}
            <section className="section-75 section-sm-80 section-md-120 section-xl-150 bg-gray-darker-custom-left bg-gray-darker">
            <div className="shell container">
                <div className="row">
                <div className="col-lg-10 col-xl-7 col-xxl-6">
                    <div className="divider-block">
                        <h2 className="wow animagion" data-wow-delay=".1s">Product Features</h2>
                        <p className="big wow animagion" data-wow-delay=".15s">
                            Products presented by galaxymica are not only stunningly beautiful but also holds the 
                            all necessary characteristics which ultimately make it last long and forever young.
                        </p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6 col-xl-4 col-xxl-3 offset-xxl-1" style={{ overflow: 'hidden' }}>
                    {/* <span className="icon-sprite sprite-home-icon-01 wow animagionBottom" data-wow-delay=".1s"></span> */}
                    <img src="assets/images/feature_scratch_resistant.svg" alt="" style={{ width: '110px', }} class="wow animagionBottom" data-wow-delay=".1s" />
                    <h5 className="wow animagion" data-wow-delay=".2s"><a href="#"> Scratch Resistant</a></h5>
                    <p className="wow animagionTop" data-wow-delay=".3s">Laminate offered by Galacymica comprises of a special strong layer which gives 3-4 times better scratch resistance than ordinary laminates.</p>
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-3 offset-top-50 offset-sm-top-0 offset-xxl-1" style={{ overflow: 'hidden' }}>
                    <img src="assets/images/feature_stain_resistant.svg" alt="" style={{ width: '110px', }} class="wow animagionBottom" data-wow-delay=".1s" />
                    <h5 className="wow animagion" data-wow-delay=".2s"><a className="postfix-xl-right--25" href="#">Stain Resistant</a></h5>
                    <p className="wow animagionTop" data-wow-delay=".1">A specialised transparent paper coated with aluminium oxide and a unique resin mixture to protect the top surface of the laminate from spills and other external agents.</p>
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-3 offset-top-50 offset-lg-top-0 offset-xxl-1" style={{ overflow: 'hidden' }}>
                    <img src="assets/images/feature_heat_resistant.svg" alt="" style={{ width: '110px', }} class="wow animagionBottom" data-wow-delay=".1s" />
                    <h5 className="wow animagion" data-wow-delay=".2s"><a href="#"> Fire Retardant </a></h5>
                    <p className="wow animagionTop" data-wow-delay=".3s">Fire Retardant laminates are halogen free and resistant to fire which help in preventing casualties and damage caused due to fire hazard.</p>
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-3 offset-top-50 offset-xxl-1" style={{ overflow: 'hidden' }}>
                    <img src="assets/images/feature_all_weather_resistant.svg" alt="" style={{ width: '110px', }} class="wow animagionBottom" data-wow-delay=".1s" />
                    <h5 className="wow animagion" data-wow-delay=".2s"><a href="#"> All Weather Resistant </a></h5>
                    <p className="wow animagionTop" data-wow-delay=".3s">We provide products which are most suitable in all kinds of weathers which ultimately makes it last longer than expected.</p>
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-3 offset-top-50 offset-xxl-1" style={{ overflow: 'hidden' }}>
                    <img src="assets/images/feature_abrasion_resistant.svg" alt="" style={{ width: '110px', }} class="wow animagionBottom" data-wow-delay=".1s" />
                    <h5 className="wow animagion" data-wow-delay=".2s"><a href="#"> Abrasion Resistant</a></h5>
                    <p className="wow animagionTop" data-wow-delay=".1">Add a great deal to the overall ;ook and vibe with the Galaxymica laminate that has abrasion-resistant and gives your room a rich look long life.</p>
                </div>
                <div className="col-md-6 col-xl-4 col-xxl-3 offset-top-50 offset-xxl-1" style={{ overflow: 'hidden' }}>
                    <img src="assets/images/feature_anti_bacterial.svg" alt="" style={{ width: '110px', }} class="wow animagionBottom" data-wow-delay=".1s" />
                    <h5 className="wow animagion" data-wow-delay=".2s"><a href="#"> Anti Bacterial</a></h5>
                    <p className="wow animagionTop" data-wow-delay=".1s">Galaxymica offers laminats with antivirus, antibacterial and antifungal properties which makes them perfect choice for kitchen and other similar applications.</p>
                </div>
                </div>
            </div>
            </section>

            {/* <!-- Latest Arrivals--> */}
            <section className="section-md-120 section-xl-130">
            <div className="shell container">
                <div className="row">
                <div className="col-lg-10 col-xl-7 col-xxl-6">
                    <div className="divider-block">
                    <h2 className="wow animagion" data-wow-delay=".35">Latest Arrivals</h2>
                    <p className="big wow animagion" data-wow-delay=".7">Some of handpicked and hand-crafted products from our linup which can light up any place.</p>
                    </div>
                </div>
                </div>
            </div>
            <div className="owl-carousel-wrap text-center wrap-fluid">
                <div className="heading-absolute wow animagion" data-wow-delay=".8s">Projects</div>
                <SRLWrapper>
                <div className="owl-carousel" data-items="1" data-sm-items="2" data-md-items="3" data-lg-items="4" data-xl-items="5" data-stage-padding="0" data-loop="true" data-margin="15" data-mouse-drag="true" data-autoplay="false" data-dots="true" data-nav-custom=".owl-custom-navigation">
                    <div className="owl-item">
                        <div className="product wow fadeUpSm" data-wow-delay=".4s">
                            <a className="thumb-custom" href="assets/images/8077.jpg" data-author="">
                                <img className="img-responsive" src="assets/images/8077.jpg" alt="" width="372" height="500"/>
                            </a>
                            <div className="product-content">
                                <a className="small" href="assets/images/8077.jpg">8077</a>
                            </div>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="product wow fadeUpSm" data-wow-delay=".5s">
                        <a className="thumb-custom"      href="assets/images/9024.jpg" data-author="">
                            <img className="img-responsive" src="assets/images/9024.jpg" alt="" width="372" height="500"/>
                        </a>
                        <a className="product-content" href="assets/images/9024.jpg">
                            <div className="small">9024</div>
                        </a>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="product wow fadeUpSm" data-wow-delay=".6s">
                        <a className="thumb-custom"      href="assets/images/9041.jpg" data-author="">
                            <img className="img-responsive" src="assets/images/9041.jpg" alt="" width="372" height="500"/>
                        </a>
                        <a className="product-content" href="assets/images/9041.jpg">
                            <div className="small">9041</div>
                        </a>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="product wow fadeUpSm" data-wow-delay=".7s">
                        <a className="thumb-custom"  href="assets/images/9140.jpg" data-author="">
                            <img className="img-responsive" src="assets/images/9140.jpg" alt="" width="372" height="500"/>
                        </a>
                        <a className="product-content" href="assets/images/9140.jpg">
                            <div className="small">9140</div>
                        </a>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="product wow fadeUpSm" data-wow-delay=".8s">
                        <a className="thumb-custom"  href="assets/images/9183.jpg" data-author="">
                            <img className="img-responsive" src="assets/images/9183.jpg" alt="" width="372" height="500"/>
                        </a>
                        <a className="product-content" href="assets/images/9183.jpg">
                            <div className="small">9183</div>
                        </a>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="product wow fadeUpSm" data-wow-delay=".9s">
                        <a className="thumb-custom"  href="assets/images/9186.jpg" data-author="">
                            <img className="img-responsive" src="assets/images/9186.jpg" alt="" width="372" height="500"/>
                        </a>
                        <a className="product-content" href="assets/images/9186.jpg">
                            <div className="small">9186</div>
                        </a>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="product wow fadeUpSm" data-wow-delay="1s">
                        <a className="thumb-custom"  href="assets/images/9247.jpg" data-author="">
                            <img className="img-responsive" src="assets/images/9247.jpg" alt="" width="372" height="500"/>
                        </a>
                        <a className="product-content" href="assets/images/9247.jpg">
                            <div className="small">9247</div>
                        </a>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="product wow fadeUpSm" data-wow-delay="1.1s">
                        <a className="thumb-custom"  href="assets/images/9292.jpg" data-author="">
                            <img className="img-responsive" src="assets/images/9292.jpg" alt="" width="372" height="500"/>
                        </a>
                        <a className="product-content" href="assets/images/9292.jpg">
                            <div className="small">9292</div>
                        </a>
                        </div>
                    </div>
                </div>
                </SRLWrapper>
                <div className="owl-custom-navigation">
                    <div className="owl-nav">
                        <div className="owl-prev wow fadeInLeftSm" data-owl-prev data-wow-delay=".8s">PREV</div>
                        <div className="owl-next wow fadeInLeftSm" data-owl-next data-wow-delay="1.6s">Next</div>
                    </div>
                </div>
            </div>
            </section>

            {/* after main total 2 sections are removed, if something goes wrong design wise then kindly refer to static versrion of galaxy */}
        
            {/* <BottomScripts /> */}

        </>
    )

}


const useScript = url => {
    useEffect(() => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            script.defer = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
    }, [url]);
};

const useStyle = url => {
    useEffect(() => {
            const style = document.createElement('link');
			style.rel = "stylesheet";
            style.href = url;
            style.async = false;
            style.defer = true;
            document.head.appendChild(style);
            return () => {
                document.head.removeChild(style);
            };
    }, [url]);
};



// componentDidMount(){

//     var loadScript = function () {
//         var tag = document.createElement('script');
//         tag.async = false;
//         tag.src = 'assets/js/core.min.js';
//         var body = document.getElementsByTagName('body')[0];
//         body.appendChild(tag);
//     }
//     loadScript();
// }


export default Home;
