import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';

// Toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Footer = () => {
    

    const initialFormData = Object.freeze({
        email: '',
    });

    const [ FormData, updateFormData ] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...FormData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        toast.info("Your request has been accepted !")

        updateFormData({
            email: "",
        })
    }


    return (
        <>

            {/* <!-- Page Footer--> */}
            <footer className="page-footer page-footer-default">
                
                <section className="section-75 section-sm-80 section-md-100 section-lg-top-140">
                <div className="shell container">
                    <div className="row">
                        <div className="col-lg-6" style={{ overflow: 'hidden' }}>
                            <div className="divider-block">
                                <h2 className="wow fadeUpSm" data-wow-delay=".4s">Newsletter Signup</h2>
                                <h6 className="wow fadeUpSm" data-wow-delay=".6s">Enter your email address to get the latest updates, events notifications and special offers delivered right to your inbox.</h6>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-5 offset-xxl-1 align-self-lg-end">
                            <form className="rd-mailform text-left rd-mailform-subscribe" style={{ overflow: 'hidden' }} data-form-output="form-output-global" data-form-type="subscribe" method="post" action="bat/rd-mailform.php">
                                <div className="form-group wow fadeUpSm" data-wow-delay=".4s">
                                    <label className="form-label form-label-subscribe" htmlFor="footer-subscribe-email">Enter your e-mail</label>
                                    <input className="form-control form-control-subscribe" id="footer-subscribe-email" type="email" name="email" data-constraints="@Email @Required" value={ FormData.email } onChange={ handleChange }  />
                                </div>
                                <button className="btn btn-primary btn-primary-white wow fadeUpSm" type="submit" data-wow-delay=".6s" onClick={ handleSubmit }>subscribe</button>
                            </form>
                        </div>
                        </div>
                </div>
                </section>

                <div className="shell container">
                <hr className="divider divider-mine-shaft" />
                </div>
                
                <section className="section-75 section-sm-80 section-md-100">
                    <div className="shell container">
                        <div className="row text-sm-left">
                        <div className="col-md-6 col-xl-3"><span className="small text-spacing-340 text-uppercase text-bold wow fadeUpSm" data-wow-delay=".1s">About company</span>
                            <p className="offset-top-20 wow fadeUpSm" data-wow-delay=".3s">Galaxy Mica advanced on its journey a decade ago and has been consistent to its promise of deliverance of the best since! We commenced on the journey with an assurance, a promise to our valuable customers; of quality! </p>
                        </div>
                        <div className="col-md-6 col-xl-2 col-xxl-3 offset-top-40 offset-lg-top-0"><span className="small text-spacing-340 text-uppercase text-bold wow fadeUpSm" data-wow-delay=".1s">INFORMATION</span>
                            <ul className="list list-marked offset-top-20 list-xs" style={{ overflow: 'hidden' }}>
                                <li className="wow fadeUpSm" data-wow-delay=".1s"><Link to="/">Home</Link></li>
                                <li className="wow fadeUpSm" data-wow-delay=".2s"><Link to="/collection">Collection</Link></li>
                                <li className="wow fadeUpSm" data-wow-delay=".3s"><a href="assets/pdf/Like-4.pdf" target='_blank'>Catalogue</a></li>
                                <li className="wow fadeUpSm" data-wow-delay=".4s"><Link to="/visual-gallery">Visual-Gallery</Link></li>
                                <li className="wow fadeUpSm" data-wow-delay=".5s"><Link to="/events">Events</Link></li>
                                <li className="wow fadeUpSm" data-wow-delay=".6s"><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-xl-3"><span className="small text-spacing-340 text-uppercase text-bold wow fadeUpSm" data-wow-delay=".1s">RECENT EVENTS</span>
                            <article className="event offset-top-20 wow fadeUpSm" data-wow-delay=".1s">
                                <p><Link to="/events">Goa New-Era Launch</Link></p>
                                <time className="small offset-top-10" dateTime="2018">June 1, 2017</time>
                            </article>
                            {/* <article className="event offset-top-20 wow fadeUpSm" data-wow-delay=".3s">
                            <p><a href="blog-post.html">Awatec was awarded with “The Best Construction Company” prize</a></p>
                            <time className="small offset-top-10" dateTime="2018">June 1, 2018</time>
                            </article> */}
                        </div>
                        <div className="col-md-6 col-xl-4 col-xxl-3 offset-top-40 offset-lg-top-0"><span className="small text-spacing-340 text-uppercase text-bold wow fadeUpSm" data-wow-delay=".1s">CONTACT INFO</span>
                            <p className="offset-top-20 wow fadeUpSm" data-wow-delay=".3s">You can always contact us via email or phone. Get in touch with us today to find out more about exciting offers.</p>
                            <ul className="list offset-top-20 text-left" style={{ overflow: 'hidden' }}>
                            <li className="wow fadeUpSm" data-wow-delay=".5s">
                                <div className="unit unit-horizontal unit-spacing-xs">
                                    <div className="unit-left"><span className="icon icon-primary fa-map-marker"> </span></div>
                                    <div className="unit-body"><a className="text-gray-lighter" href="https://goo.gl/maps/Enbm2VCvCUxBuWE88" target="_blank">1, Lakshmi Complex, Opp. National Handloom, Near Satluj Hotel, Naroda Road, Ahmedabad, Gujarat</a></div>
                                </div>
                            </li>
                            <li className="wow fadeUpSm" data-wow-delay=".6s">
                                <div className="unit unit-horizontal unit-spacing-xs">
                                <div className="unit-left"><span className="icon icon-primary fa-phone"></span></div>
                                <div className="unit-body"><a className="text-gray-lighter" href="tel:9726419155">+91 9726419155</a></div>
                                </div>
                            </li>
                            <li className="wow fadeUpSm" data-wow-delay=".7s">
                                <div className="unit unit-horizontal unit-spacing-xs">
                                <div className="unit-left"><span className="icon icon-primary fa-envelope"></span></div>
                                <div className="unit-body"><a className="text-gray-lighter" href="mailto:info@galaxymica.com">info@galaxymica.com</a></div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </section>

                <section className="section-25 bg-gray-base-custom">
                <div className="shell container">
                    <p className="small-xs">Copyright	<span id="copyright-year"></span>	All rights reserved by Galaxy Mica
                    .	Developer by <a href="https://krioskcreata.com" target="_blank">kriosk Creata</a>.
                    </p>
                </div>
                </section>

            </footer>
            
            <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            
        </>

    )
}

export default Footer
