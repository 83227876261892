import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
// import '../assets/css/bootstrap.css';
// import '../assets/css/style.css';
import { Helmet } from 'react-helmet';
// import ScriptTag from 'react-script-tag';
// import BottomScripts from '../components/bottomScripts';
import axios from 'axios';
import axiosInstance from '../axios';
import { useHistory } from "react-router";
import { SRLWrapper } from 'simple-react-lightbox';
// import '../assets/css/module.bootstrap.css';
// import '../assets/css/module.style.css';


const Product = () => {

    useScript('assets/js/core.min.js');
    useScript('assets/js/script.js');

    useStyle('assets/css/bootstrap.css');
    useStyle('assets/css/style.css');


    // Fstate : Fullsheet (fetched fullsheets will be stored here)
    const [data, setData] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(true);


    // use-effect : Fullsheet (fetching the fullsheets)
    useEffect(() => {
        if (fetchStatus == true){

            async function fetchProducts() {
                const request = await axios.get('https://panel.galaxymica.com/api/product/', {
                    headers: {
                        Authorization: 'Bearer ' + '90411fb0941f2b9c5e4f3bc976b301f8c1ec31e3 token',
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                });
                setData(request.data);
                console.log(request.data)
                return request;
            }

            fetchProducts()
            .then(function() {
                console.log(data)
                setFetchStatus(true);
            })
            .catch(error => {
                console.log(error);
                setFetchStatus(false);
                console.log(fetchStatus);
                return error;
            })

        }
    }, [fetchStatus]);


    const history = useHistory();

    const demoPush = (e) => {
        e.preventDefault();
        // history.push("/search?q=" + searchFormData.search);
        // history.replace({
        //     pathname: '/search',
        //     search: '?q=' + searchFormData.search,
        // })
    }

    
    return (
        
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />

                <title>Products - Galaxy Mica</title>
            </Helmet>

            <Header />

            {/* <!-- Classic Breadcrumbs--> */}
            <section className="breadcrumb-classic">
                <div className="rd-parallax">
                    <div className="rd-parallax-layer" data-speed="0.25" data-type="media" data-url="assets/images/products.jpg"></div>
                    <div className="rd-parallax-layer section-top-200 section-md-top-150 section-lg-top-260" data-speed="0" data-type="html">
                    <div className="shell container">
                        <ul className="list-breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/products">Products</Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </section>

            <section className="section-50 section-md-60 section-lg-70">
                {/* <!-- Title-Para --> */}
                <div className="shell container">
                    <div className="row justify-content-lg-between">
                        <div className="col-lg-8 col-xl-7 col-xxl-6">
                            <div className="inset-md-right-30 inset-lg-right-0">
                                <h1>Product</h1>
                                <p>Our residential, commercial and industrial lot of laminate sheets are the widest collection one will see. And in each category, we have decorative laminates, beautiful gathering of solid colors and impressive sort of prints and patterns, shining gloss laminates, super matt finish and textured laminates. Of course the natural looking wood grain sheets are the base that we create.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
            fetchStatus ? 
                   
                <>

                    <section class="">
                        <div class="shell container row-offset-2">
                            <div class="row justify-content-sm-center">
                                <div class="col-xl-12 col-lg-8 col-md-10" style={{ marginBottom: '70px' }}>

                                    {
                                        data.map((data, index) => {
                                            return (
                                                <div class="row align-items-lg-center" style={{ marginTop: '60px' }}>
                                                    <div class="col-xl-7 col-xxl-6">
                                                        <h2>{ index + 1 }. { data.name }</h2>
                                                        <h6>{ data.detail }</h6>
                                                        <br />
                                                        <Link to={`/product/${data.name_slug}`}><button className="btn btn-primary">Explore Collection</button></Link>
                                                    </div>
                                                    <div class="col-xl-5 offset-xxl-1">
                                                        <img class="img-responsive" src={ data.image1 } alt="" width="610" height="410"/>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </section>

                </>

            : 
                <section style={{ paddingBottom: '90px' }}>
                    <div className="container">
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-12 col-xl-12 col-xxl-12 d-flex justify-content-center" style={{ paddingBottom: '30px' }}>
                                <h6>Something went wrong while fetching the products</h6>
                            </div>
                            <div className="col-lg-12 col-xl-12 col-xxl-12 d-flex justify-content-center">
                                <button className="btn btn-primary btn-icon btn-icon-right" onClick={ () => setFetchStatus(true) }>Refresh<span className="icon fa-refresh"></span></button>
                            </div>
                        </div>
                    </div>
                </section>
            }

                 
            
            {/* <BottomScripts /> */}
            
        </>

    )
}

const useScript = url => {
    useEffect(() => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            script.defer = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
    }, [url]);
};

const useStyle = url => {
    useEffect(() => {
            const style = document.createElement('link');
			style.rel = "stylesheet";
            style.href = url;
            style.async = false;
            style.defer = true;
            document.head.appendChild(style);
            return () => {
                document.head.removeChild(style);
            };
    }, [url]);
};


export default Product

