import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import { Helmet } from 'react-helmet';
import BottomScripts from '../components/bottomScripts';
import PhotoSwipe from '../components/photoswipe';
import { SRLWrapper } from 'simple-react-lightbox';


const Events = () => {

    useScript('assets/js/core.min.js');
    useScript('assets/js/script.js');

    useStyle('assets/css/bootstrap.css');
    useStyle('assets/css/style.css');


    return (
        // react syntactic-suger
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />

                <title>Events - Galaxy Mica</title>
            </Helmet>
        
            <Header />

            {/* <!-- Breadcrumbs--> */}
            <section className="breadcrumb-classic">
                <div className="rd-parallax">
                    <div className="rd-parallax-layer" data-speed="0.25" data-type="media" data-url="assets/images/events.jpg"></div>
                    <div className="rd-parallax-layer section-top-200 section-md-top-150 section-lg-top-260" data-speed="0" data-type="html">
                        <div className="shell container">
                            <ul className="list-breadcrumb">
                            <li><Link to="/">home</Link></li>
                                <li><Link to="/events">Events</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-75 section-md-100 section-lg-150">

                {/* <!-- Title-Para --> */}
                <div className="shell container">
                    <div className="row justify-content-lg-between">
                        <div className="col-lg-8 col-xl-7 col-xxl-6">
                            <div className="inset-md-right-30 inset-lg-right-0">
                                <h1>Events</h1>
                                <p>Moments that walk united with us always – call for a click and be framed in our albums. Here are some joyous events that pump our hearts and we feel satisfactory about our jobs. </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <! -- Products --> */}
                <div className="container">
                    <div className="row">

                        {/* <!-- Filters--> */}
                        <div className="col-lg-12">
                            <div className="isotope-filters isotope-filters-horizontal">
                                <ul className="list-isotop">
                                    {/* <!-- <li><a className="active" data-isotope-filter="*" data-isotope-group="gallery" href="#">All</a></li> --> */}
                                    <li><a className="active" data-isotope-filter="event-1" data-isotope-group="gallery" href="#">GOA - NEW ERA LAUNCHING</a></li>
                                    {/* <li><a data-isotope-filter="event-2" data-isotope-group="gallery" href="#">Consulting</a></li> */}
                                </ul>
                            </div>
                        </div>

                        {/* <!-- Content--> */}
                        <div className="col-lg-12">
                        <SRLWrapper>
                            <div className="row isotope isotope-lg" data-isotope-layout="masonry" data-isotope-group="gallery">

                                <div className="isotope-item col-xs-12 col-sm-6" data-filter="event-1">
                                    <div className="product fadeUpSm" data-wow-delay=".5s">
                                        <a className="thumb-custom" data-photo-swipe-item="" href="assets/images/event_1.jpg" data-author="">
                                            <img className="img-responsive" src="assets/images/event_1.jpg" alt=""/>
                                        </a>
                                    </div>
                                </div>

                                <div className="isotope-item col-xs-12 col-sm-6" data-filter="event-1">
                                    <div className="product fadeUpSm" data-wow-delay=".5s">
                                        <a className="thumb-custom" data-photo-swipe-item="" href="assets/images/event_2.jpg" data-author="">
                                            <img className="img-responsive" src="assets/images/event_2.jpg" alt=""/>
                                        </a>
                                    </div>
                                </div>

                                <div className="isotope-item col-xs-12 col-sm-6" data-filter="event-1">
                                    <div className="product fadeUpSm" data-wow-delay=".5s">
                                        <a className="thumb-custom" data-photo-swipe-item="" href="assets/images/event_3.jpg" data-author="">
                                            <img className="img-responsive" src="assets/images/event_3.jpg" alt=""/>
                                        </a>
                                    </div>
                                </div>

                                <div className="isotope-item col-xs-12 col-sm-6" data-filter="event-1">
                                    <div className="product fadeUpSm" data-wow-delay=".5s">
                                        <a className="thumb-custom" data-photo-swipe-item="" href="assets/images/event_4.jpg" data-author="">
                                            <img className="img-responsive" src="assets/images/event_4.jpg" alt=""/>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            </SRLWrapper>

                        </div>

                    </div>
                </div>

            </section>

            {/* <PhotoSwipe /> */}
        
        </>

    )
}

const useScript = url => {
    useEffect(() => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            script.defer = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
    }, [url]);
};

const useStyle = url => {
    useEffect(() => {
            const style = document.createElement('link');
			style.rel = "stylesheet";
            style.href = url;
            style.async = false;
            style.defer = true;
            document.head.appendChild(style);
            return () => {
                document.head.removeChild(style);
            };
    }, [url]);
};


export default Events


















// class based component :
// export class events extends Component {
//     render() {
//         return (
//             <div>

//                 <Header />

                

//                 <BottomScripts />
                
//             </div>
//         )
//     }
// }

// export default events

