import axios from "axios";



const baseURL = 'https://panel.galaxymica.com/api/';

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 15000,
    headers: {
        Authorization: 'Bearer ' + '90411fb0941f2b9c5e4f3bc976b301f8c1ec31e3 token',
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

export default axiosInstance;