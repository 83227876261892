import React from 'react'
import { Link } from 'react-router-dom';



const Header = () => {
    return (
        <>

            <header id="HeadSection">
                <div className="container-wrapper">
                    <div className="logo-container">
                        <div className="logo display-inline">
                            <Link to="/">
                                <img src="assets/vsg/images/galaxymica.svg" alt="Galaxymica" style={{ height: '70px' }} />
                            </Link>
                        </div>
                        <div className="nav-login display-inline">
                            <a href="#msi-mmenu" title="product-menu" className="glyphicon glyphicon-menu-hamburger dropdown-toggle toggle-menu hmbrgr-mbl">MENU</a>
                            
                            <Link to="/" className="cartbtn-header cartbtn-header1">
                                <img src="assets/vsg/images/back.png" alt="" style={{ marginBottom: '3px' }} />
                                <span className="SampleSpan">BACK TO HOME</span>
                            </Link>
                            <Link to="/" className="cartbtn-header cartbtn-header2">
                                <img src="assets/vsg/images/back.png" alt="" style={{ marginBottom: '3px' }} />
                                <span className="SampleSpan">Back</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <hr />

                {/* <!-- menu --> */}
                <div id="menu-wrap" className="topnav-wrapper" style={{ display: 'flex', justifyContent: 'center' }} >
                    <div className="container-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="msi-navbar-main" id="msi-navbarmain">
                                    <li>
                                        <nav id="msi-mmenu" aria-label="menu">
                                            <ul id="msi-navbar" className="hidden">
                                                <li>
                                                    <a href="#">Application Area</a>
                                                    <ul className="sub-menu">
                                                        <li>
                                                            <Link to="/visual-gallery" target="_self">Living Room</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/products">Explore Products</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact" target="_self">Contact Us</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default Header;
