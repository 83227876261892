import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useRouteMatch } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import axiosInstance from '../../axios';
// import '../../assets/vsg/css/classic-10_7.css';
// import '../../assets/vsg/css/msi-ui6c3f.css';



function VisualGallery() {

    var hs = document.getElementsByTagName('style');
    // console.log(hs)
    // for (var i=0, max = hs.length; i < max; i++) {
        
    // }

    // require("../../assets/vsg/css/msi-ui6c3f.css");
    // require("../../assets/vsg/css/classic-10_7.css");
    // require("../../assets/vsg/css/style.css");
    // require("../../assets/vsg/css/obec92.css");
    // require("https://fonts.googleapis.com/css?family=Open+Sans:400,600|Raleway:400,500&amp;selection.subset=latin-ext");
    // require("../../assets/vsg/css/font-awesome.min.css");

    useStyle('assets/vsg/css/msi-ui6c3f.css');
	useStyle('assets/vsg/css/classic-10_7.css');
    useStyle('assets/vsg/css/style.css');
    useStyle('assets/vsg/css/obec92.css');
    useStyle('https://fonts.googleapis.com/css?family=Open+Sans:400,600|Raleway:400,500&amp;selection.subset=latin-ext');
    useStyle('assets/vsg/css/font-awesome.min.css');
    

    
    useHeadScript('assets/vsg/js/jquery0260.js');
    useHeadScript('assets/vsg/js/magic.js');
    useScript('assets/vsg/js/mc-validate.js');
    useScript('assets/vsg/js/modal.js');
    useScript('assets/vsg/js/embed.js');
    useScript('assets/vsg/js/rum.js');
    useScript('assets/vsg/js/173033.js');
    useScript('assets/vsg/js/obec92.js');
    useScript('assets/vsg/js/jqueryui3024.js');
    useScript('assets/vsg/js/base7581.js');

    const doAction = (url, name, brand) => {

        window.doAction(url, name, brand)
        
        // window.location.reload(); 
        
    }

    const [data, setData] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(true);
    
    // use-effect : Fullsheet (fetching the fullsheets)
    useEffect(() => {
        if (fetchStatus == true){
            
            async function fetchProducts() {
                const request = await axios.get('https://panel.galaxymica.com/api/collection/', {
                    headers: {
                        Authorization: 'Bearer ' + '90411fb0941f2b9c5e4f3bc976b301f8c1ec31e3 token',
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                });
                setData(request.data);
                console.log(request.data)
                return request;
            }

            fetchProducts()
            .then(function() {
                console.log("success");
            })
            .catch(error => {
                console.log(error);
                setFetchStatus(false);
                return error;
            })


        } 
        // else {
        //     console.log("in else ========================");
        // }
      

    }, [fetchStatus]);


    return (
        <>
            <Helmet>

                <meta charset="utf-8" />
                <meta http-equiv="content-type" content="text/html;charset=utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />

                <title>Visualizer - Galaxy Mica</title>

                {/* <link rel="preload" href="../../assets/vsg/css/msi-ui6c3f.css" />
                <link rel="preload" href="../../assets/vsg/css/classic-10_7.css" />
                <link rel="preload" href="../../assets/vsg/css/style.css" />
                <link rel="preload" href="../../assets/vsg/css/font-awesome.min.css" /> */}

            </Helmet>

            <Header />

            {/* <!-- main section --> */}
            <section role="main">

                <div className="ss-section">
                    <div className="container">

                        <div className="row space-top-2x">

                            {/* <!-- right content --> */}
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-4 col-xl-push-8" id="EnvisionBathroom">
                                <h1 className="text-center vsg-title">ENVISION YOUR SPACE</h1>
                                <p className="text-center bathroom_text">
                                        Encounter the enlivened version of our products to ensure their presence and their beauty that we promise to bring to your space. 
                                </p>
                                <div className="hidden-xs hidden-sm hidden-md" id="instructions">
                                    <div className="instruct">
                                        <h4 className="text-center instruct-heading">INSTRUCTIONS</h4>
                                        <div className="image123">
                                            <img className="img-responsive" Align="middle" src="assets/vsg/images/onetwothree.png" alt="onetwothree" />
                                        </div>
                                        <div className="instruct-text">
                                            <p className="p1">Choose Application Area.</p>
                                            <p className="p2">Select any of our Product.</p>
                                            <p className="p3">Witness the Magic!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="hidden-xs hidden-sm hidden-md hidden-lg selected-opt">
                                        <div className="h5 text-center">Selected option</div>
                                        <br/>
                                        <div className="col-lg-9 selected-backsplash-left">
                                            <h3 className="text-center brand-name" style={{ marginTtop: '0px' }}>Like - 1mm :</h3>
                                            <img id="backsplashthumbimg" className="image-selected img-responsive" style={{ height: '100px', width: '100px', objectFit: 'cover !important' }} src="assets/images/8079.jpg"/>
                                            <a className="backsplashprodid text-center post-title">8079</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- left main image --> */}
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-8 col-xl-pull-4 space-top">
                                <canvas id="bhCanvas" className="hide" height="712" width="930"></canvas>
                                
                                <img src="assets/vsg/images/Visual_Gallary_Living.png" alt="" style={{ position: 'relative', width: '100%', backgroundImage: "url('assets/images/8079.jpg')", backgroundSize: '16%' }} className="main-image" id="main-image" />
                            </div>
                            
                        </div>

                        <div className="row space-top bth_visualizerwidth" id="BathroomVisualizer">
                            <div className="col-xs-12 tabs" id="tabsalign">
                            {
                                fetchStatus ? 

                                <>
                                    {/* <!-- Buttons right --> */}
                                    <ul className="nav nav-tabs">

                                        <li className="hidden-sm hidden-md hidden-lg"> <br /><br /></li>

                                        <li className="pull-left backsplash active">
                                            <a href="#product_like" data-toggle="tab" >Like - 1mm</a>
                                        </li>
                                    </ul>

                                    {/* <!-- Tab content --> */}
                                    <div className="tab-content">
                                        
                                        <div id="product_like" className="tab-pane fade backsplash in active">
                                            {/* <!-- title --> */}
                                            <div className="row">
                                                <label className="col-xs-12 prod-text">Like - 1mm :<a className="backsplashprodname post-title"></a><br /></label>
                                                <br />
                                            </div>
                                            <div className="row  tab-scroll subcategory-tab">

                                                {
                                                data.map((data) => {
                                                    return (
                                                        <div key={data.id} className="subcategory-img text-left" data-filter="2924" data-subcategory="backsplash">
                                                            <span data-filter="2924" className="text-center">
                                                                <a href="javascript:void(0)" style={{ cursor: 'pointer' }} className="crop">
                                                                    <img className="thumbimg product-image lazy" id="call-magic" src={data.image1} loading="lazy" alt={data.product_code} onClick={ () => doAction(data.image1, data.product_code, 'Like') } style={{ objectFit: 'cover !important' }} />
                                                                </a>
                                                                <figcaption className="img-title">{data.product_code}</figcaption>
                                                                <div style={{ width: '100%', marginTop: '-50px', paddingBottom: '30px' }}><a href={data.image1} target="_blank"><button className="buttonin"><i className="fa fa-search-plus" style={{ color: '#8B6F4E' }}></i></button></a></div>
                                                            </span>
                                                        </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </>

                                : 
                                    <section style={{ paddingBottom: '90px', textAlign: 'center', }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-12 col-xxl-12 d-flex justify-content-center" style={{ paddingBottom: '30px' }}>
                                                    <h6>Something went wrong while fetching the products</h6>
                                                </div>
                                                <div className="col-lg-12 col-xl-12 col-xxl-12 d-flex justify-content-center">
                                                    <button className="btn btn-primary btn-icon btn-icon-right" onClick={ () => setFetchStatus(true) }>Refresh</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }

                            </div>
                        </div>

                    </div>

                </div>

            </section>


            <Footer />

        </>
    );
}

const useStyle = url => {
    useEffect(() => {
            const style = document.createElement('link');
			style.rel = "stylesheet";
            style.href = url;
            style.async = false;
            style.defer = true;
            document.head.appendChild(style);
            return () => {
                document.head.removeChild(style);
            };
    }, [url]);
};

const useHeadScript= url => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = false;
        script.defer = true;
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, [url]);
};

const useScript = url => {
    useEffect(() => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            script.defer = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
    }, [url]);
};


export default VisualGallery;
